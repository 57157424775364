<template>
  <div :class="['relative min-h-screen',themeStyles(theme)]">
    <div :class="['max-wrapper relative p-8 py-12', {readable}]">
      <BlockContent v-if="content" :content="content" />
      <Form
        class="flex size-full flex-col justify-between"
        form-name="customContactForm"
        :validation-schema="validationSchema"
        @submit="onSubmit"
      >
        <div v-for="(field, index) in formFields" :key="index">
          <div v-if="!['textArea', 'file'].includes(field.inputType)" class="input-con">
            <label class="font-bold" :for="field.fieldId">{{ field.fieldName }} {{ field.required ? '*' : '' }}</label>
            <Field
              :id="field.fieldId"
              v-model="formValues[field.fieldId]"
              :name="field.fieldId"
              :type="getFieldType(field.inputType)"
              :placeholder="field.placeholder"
            />
            <ErrorMessage :name="field.fieldId" />
          </div>

          <div v-if="field.inputType === 'textArea'" class="relative mb-6 flex h-auto w-full flex-wrap items-center md:mb-8">
            <label class="font-bold" :for="field.fieldId">{{ field.fieldName }} {{ field.required ? '*' : '' }}</label>
            <Field
              :id="field.fieldId"
              v-model="formValues[field.fieldId]"
              class="w-full"
              as="textarea"
              :name="field.fieldId"
              cols="100"
              rows="10"
            />
            <ErrorMessage :name="field.fieldId" />
          </div>
          <div v-if="field.inputType === 'file'" class="relative mb-6 flex h-auto w-full flex-wrap items-center md:mb-8">
            <label class="font-bold" :for="field.fieldId">{{ field.fieldName }} {{ field.required ? '*' : '' }}</label>
            <Field
              :id="field.fieldId"
              :class="[`
                w-full text-sm text-white
                file:flex file:h-full
                file:items-center file:border file:bg-stone-50
                file:px-3 file:text-xs file:font-medium
                file:text-stone-700 hover:file:cursor-pointer
                hover:file:bg-blue-50 hover:file:text-blue-700
              `]"
              :name="field.fieldId"
              :type="field.inputType"
              :multiple="field.multipleFiles"
              @input="(event) => addFile({fieldId: field.fieldId, event})"
            />
            <ErrorMessage :name="field.fieldId" />
            <div v-if="previewImgs" class="preview flex flex-wrap items-start gap-4 py-4">
              <img v-for="(img, i) in previewImgs" :key="i" class="bg-primary-50 border-accent w-6/12 max-w-[200px] rounded-md border border-solid" :src="img" :alt="`image preview ${index + 1}`">
            </div>
          </div>
        </div>
        <POButton
          v-if="!finished"
          class="!w-fit"
          btn-style="normal"
          color="primary"
          size="sm"
          type="submit"
        >
          <div v-if="!loading" class="flex w-full items-center">
            <div>Submit</div>
          </div>
          <div v-if="loading" class="flex w-full items-center justify-between gap-x-4">
            <div>Submitting</div>
            <svg class="size-6 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              />
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
          </div>
        </POButton>
      </Form>
      <div
        v-if="finished"
        class="bg-accent text-accent-text flex items-center justify-center rounded-md p-4"
      >
        <div class="mb-0 p-0 text-xl font-bold">
          Thank you for contacting us, we'll be in touch soon
        </div>
      </div>
      <div
        v-if="error"
        class="flex items-center justify-center rounded-md bg-red-500 p-4 text-white"
      >
        <div class="mb-0 p-0 text-xl font-bold">
          There seems to have been an issue with the form submittion, please try again.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Field, ErrorMessage, Form, type GenericObject, type SubmissionContext } from 'vee-validate'
import axios from 'axios'

interface ContactFormData {
  [key: string]: any
}

interface FormInputValue {
  type: string;
  key: string;
  value: string | number
}
interface FormRequest {
  from?: {
    email: string;
    company: string;
  };
  to: string;
  subject: string;
  formData: FormInputValue[] | ContactFormData | string;
  attachment?: FileList
}

interface FormConfig {
  emailFrom?: string;
  company?: string;
  emailTo: string;
  subject: string;
}

interface FormField {
  required: boolean;
  fieldName: string;
  placeholder: string;
  fieldId: string;
  inputType: 'text' | 'email' | 'phone' | 'textArea' | 'date' | 'datetime-local' | 'file';
  multipleFiles: boolean | null | undefined;
}

interface Props {
  readable: boolean;
  theme: Theme;
  content: BlockContent;
  config: FormConfig;
  formFields: FormField[]
}

const props = defineProps<Props>()

const phoneRegExp = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/
const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

const formValues = ref<ContactFormData>({})
const loading = ref(false)
const finished = ref(false)
const error = ref(false)
const previewImgs = ref<string[]>([])

function getFieldType (type: FormField['inputType']) {
  const typeMap = {
    text: 'text',
    textArea: 'text',
    phone: 'tel',
    email: 'email',
    file: 'file',
    date: 'date',
    'datetime-local': 'datetime-local'
  }
  return typeMap[type]
}

const validationSchema = computed(() => {
  const rules: ContactFormData = {}
  props.formFields.forEach((item: FormField) => {
    rules[item.fieldId] = validationRule(item)
  })
  return rules
})

function validationRule (field: FormField) {
  if (field.inputType === 'phone' && field.required) { return (val: any) => !phoneRegExp.test(val) ? `${field.fieldName} is not a valid phone number` : true }
  if (field.inputType === 'email' && field.required) { return (val: any) => !emailRegExp.test(val) ? `${field.fieldName} is not a valid email` : true }
  if (field.inputType === 'date' && field.required) { return (val: any) => !val ? `${field.fieldName} is not a valid date` : true }
  if (field.inputType === 'datetime-local' && field.required) { return (val: any) => !val ? `${field.fieldName} is not a valid date time` : true }
  if (field.inputType === 'file' && field.required) { return (val: any) => !val ? `${field.fieldName} is required` : true }
  if (field.required) { return (val: any) => !val ? `${field.fieldName} is required` : true }
  return true
}

function prepareFormData (formData: ContactFormData) {
  return Object.entries(formData).map((item: any[]) => {
    const [key, value] = item
    return {
      type: fieldInputTypes.value[key],
      key,
      value
    }
  })
}

const fieldInputTypes = computed(() => {
  const typesById: ContactFormData = {}
  props.formFields.forEach((item: FormField) => {
    typesById[item.fieldId] = item.inputType
  })
  return typesById
})

function buildFormData (formData: any, data:any, parentKey?: string) {
  if (data && typeof data === 'object' && !Array.isArray(data) && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data ?? ''
    formData.append(parentKey, value)
  }
}

function jsonToFormData (data: any) {
  const formData = new FormData()
  buildFormData(formData, data)
  return formData
}

function readFile (file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e: ProgressEvent<FileReader>) {
      if (e?.target?.result) { previewImgs.value.push(e.target.result as string) }
      resolve('success')
    }
    reader.onerror = (error) => {
      reject(error)
    }
  })
}
async function addFile ({ fieldId, event }: {fieldId: string, event: Event}) {
  const files = (event.target as HTMLInputElement)?.files
  if (!files?.length) { return }

  if (Array.isArray(formValues.value?.[fieldId]) && formValues.value?.[fieldId].length > 0) {
    formValues.value[fieldId].push(...files)
  } else {
    formValues.value[fieldId] = files
  }

  for (const element of files) {
    const file = element
    await readFile(file)
  }
}

function resetStatesAfterDelay (seconds: number) {
  return new Promise(resolve =>
    setTimeout(() => {
      loading.value = false
      finished.value = false
      error.value = false
      resolve('reset')
    }, seconds * 1000)
  )
}

const onSubmit = (values: ContactFormData, e: SubmissionContext<GenericObject>) => {
  const realValues = formValues.value
  e.evt?.preventDefault()
  submitData(realValues)
}

async function submitData (values: ContactFormData) {
  const { emailFrom, company, emailTo, subject } = props.config
  const fileKey = Object.keys(values).find((key: string) => fieldInputTypes.value[key] === 'file')
  const data: FormRequest = {
    to: emailTo,
    subject,
    formData: JSON.stringify(prepareFormData(values))
  }
  if (emailFrom && company) {
    data.from = {
      company,
      email: emailFrom
    }
  }
  if (fileKey) { data.attachment = values[fileKey] }

  const formData = jsonToFormData(data)

  loading.value = true
  try {
    const res = (await axios.post('https://form.projectopal.co', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }))?.data as {message: string}
    if (res?.message === 'success') {
      loading.value = false
      finished.value = true
      resetStatesAfterDelay(5)
    } else {
      loading.value = false
      finished.value = false
      error.value = true
      resetStatesAfterDelay(5)
    }
  } catch (e) {
    loading.value = false
    finished.value = false
    error.value = true
    resetStatesAfterDelay(5)
  }
}
const emit = defineEmits(['mounted', 'updated'])
onMounted(() => emit('mounted'))
onUpdated(() => emit('updated'))
</script>
<style lang="scss" scoped>
.max-wrapper {
  @apply py-12 h-full;
}
label {
  @apply mb-2;
}
input,
textarea,
select {
  color-scheme: dark;
  @apply text-background-200-text bg-background-200 w-full min-w-full h-12 leading-[3rem] px-3 py-2 rounded-md border-solid border-[1px] border-background-400;
  margin: 0;
  display: block;
}
textarea {
  height: auto;
}
span[role='alert'] {
  @apply bg-red-500 absolute top-[calc(100%+2.5rem)] py-4 md:py-0 text-[10px] md:text-[16px] rounded-md border-red-500 border-solid border-[1px] text-white px-2 h-6 flex items-center justify-center
}
.prefix-wrapper {
  input {
    @apply min-w-0;
  }
}
.input-con {
  @apply relative flex w-full mb-12 md:mb-[4.5rem] h-12 flex-wrap items-center;
  &.textarea { @apply h-auto; }
}
.multi-select { @apply relative flex w-full mb-12 }
</style>
